import React, { useEffect, useState, useCallback, useRef } from 'react';
import moment from 'moment';
import { Table, DatePicker, ModalFormDetail } from 'components';
import { FormDetail } from './form-detail';
import { Box, IconButton, Typography, Stack } from '@mui/material';
import Utils from 'commons/utils';
import ApiServices from 'services';
import {
  BsBoxArrowInUpRight,
  BsArrowDownCircle,
  BsPlusCircle,
  BsFilter,
  BsFillStickiesFill,
} from 'react-icons/bs';

import SearchIcon from '@mui/icons-material/Search';

import { Columns } from './columns';
import { ContainerHeader, ButtonAdd, ButtonImport, ButtonExport } from './styles';
import { RESPONSE_STATUSES } from 'commons/constants';
import { debounce } from 'lodash';
import { UpdateJob } from './update-job';

const CLIENT_COMPLAINT_TEMPLATE = 'ClientComplaint_TemplateIm';

const START_DATE = moment().startOf('month').format();
const END_DATE = moment().endOf('date').format();

const DEFAULT_FILTER = {
  DateFrom: START_DATE,
  DateTo: END_DATE,
};

function TaskJobPage() {
  const inputRef = useRef();
  const [dataList, setDataList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingImport, setLoadingImport] = useState(false);
  const [selected, setSelected] = useState(null);
  const [totalRecords, setTotalRecords] = useState(0);
  const [filter, setFilter] = useState(DEFAULT_FILTER);
  const [usersList, setUsersList] = useState([]);

  const [selectedShowModal, setSelectedShowModal] = useState(null);

  // search Date
  const [startDate, setStartDate] = useState(START_DATE);
  const [endDate, setEndDate] = useState(END_DATE);

  const initData = (query) => {
    setLoading(true);
    return ApiServices.getSchedulePersonUsers(query ? query : filter)
      .then((res) => {
        if (res?.data) {
          setDataList(res?.data || []);
        }
      })
      .catch((err) => console.warn('get getSchedulePersonUsers:', err))
      .finally(() => setLoading(false));
  };

  const getUsers = async () => {
    try {
      const res = await ApiServices.getUsers();
      if (res?.data) {
        setUsersList(res?.data || []);
      }
    } catch (error) {
      console.log('getUsers Failed:::', error);
    }
  };

  const handleSearchColumn = (event) => {
    // setFilter((prev) => {
    //   const searchArray = { ...prev.searchArray };
    //   const { value, name } = event.target;
    //   if (!value) {
    //     delete searchArray[name];
    //     return { ...prev, pageNumber: 1, searchArray };
    //   }
    //   searchArray[name] = value;
    //   return { ...prev, pageNumber: 1, searchArray };
    // });
  };
  const timeoutGetData = useCallback(
    debounce((newFilter) => {
      initData(newFilter);
    }, 500),
    [],
  );
  useEffect(() => {
    timeoutGetData(filter);
  }, [filter]);

  useEffect(() => {
    getUsers();
  }, []);

  const reloadData = async () => {
    return await initData(filter);
  };

  const onCloseModal = () => {
    setSelectedShowModal(null);
  };

  const handleChangeStartDate = useCallback(
    (event) => {
      setStartDate(event.target.value);
    },
    [startDate],
  );
  const handleChangeEndDate = useCallback(
    (event) => {
      setEndDate(event.target.value);
    },
    [endDate],
  );

  const handleSearchDate = (startDateSearch, endDateSearch) => {
    if (!startDateSearch || !endDateSearch) return;
    setFilter((prev) => ({ ...prev, DateFrom: startDateSearch, DateTo: endDateSearch }));
  };

  const deleteItem = async (row) => {
    if (!row?.id) return;

    setLoading(true);
    return ApiServices.deleteScheduleUser(row?.id)
      .then((res) => {
        if (res.status === RESPONSE_STATUSES.OK) {
          window.UIMessage?.success('Xóa thành công');
          reloadData();
        }
      })
      .catch((err) => {
        window.UIMessage?.error('Xóa thất bại !!!');
        console.warn('deleteClientComplaint', err);
      })
      .finally(() => setLoading(false));
  };

  const onHandleImport = (event) => {
    if (loadingImport || !event) return;
    const file = event?.target?.files[0];
    inputRef.current.value = '';
    if (!file) {
      window.UIMessage?.error('Tải file excel thất bại !!!');
      return;
    }
    setLoadingImport(true);
    return ApiServices.importExcelClientComplaint({ excelFile: file })
      .then((res) => {
        if (res?.data) {
          window.UIMessage?.success(`Import excel thành công`, 3000);
          // event.target.value = '';
          reloadData();
        }
      })
      .catch((err) => {
        console.warn('import excel - Clients Compalint', err);
        window.UIMessage?.error('Import excel thất bại !!!');
      })
      .finally(() => setLoadingImport(false));
  };
  const handleExportClient = () => {
    setLoading(true);
    return ApiServices.exportExcelClientComplaint()
      .then((res) => {
        if (res?.data) {
          Utils.exportTemplate(
            res?.data,
            `DS_Khách_Hàng_Khiếu_Nại_${Utils.formatSimpleDate(moment())}.xlsx`,
          );
        }
      })
      .catch((err) => {
        console.log('Export Client file error:', err);
        window.UIMessage?.error('Tải thất bại !!!');
      })
      .finally(() => setLoading(false));
  };
  const handleExportTemplate = () => {
    setLoading(true);
    return ApiServices.exportTemplates(CLIENT_COMPLAINT_TEMPLATE)
      .then((res) => {
        if (res?.data) {
          Utils.exportTemplate(res?.data, `Biểu_Mẫu_Khách_Hàng_Khiếu_Nại.xlsx`);
        }
      })
      .catch((err) => {
        console.log('Export Tempalte error:', err);
        window.UIMessage?.error('Tải thất bại !!!');
      })
      .finally(() => setLoading(false));
  };
  const renderHeader = () => (
    <>
      <ContainerHeader>
        <Stack spacing={2} direction="row">
          <Box sx={{ width: '160px' }}>
            <DatePicker
              sx={{
                marginBottom: '10px',
                padding: 0,
              }}
              width={100}
              marginTop="0"
              size="small"
              onChange={handleChangeStartDate}
              value={startDate}
              error={true}
              label="Từ ngày"
            />
          </Box>
          <Box sx={{ width: '160px' }}>
            <DatePicker
              sx={{
                marginBottom: '10px',
                padding: 0,
              }}
              width={100}
              marginTop="0"
              size="small"
              onChange={handleChangeEndDate}
              value={endDate}
              error={true}
              label="Đến ngày"
            />
          </Box>

          <IconButton
            aria-label="filter"
            sx={{ color: 'blue' }}
            onClick={() => handleSearchDate(startDate, endDate)}
          >
            <SearchIcon />
          </IconButton>
          {/* <IconButton aria-label="filter" sx={{ color: 'red' }}>
            <SearchOffIcon />
          </IconButton> */}
        </Stack>
      </ContainerHeader>

      {/* <IconButton aria-label="filter" sx={{ color: '#6C757D' }}>
        <BsFilter />
      </IconButton> */}
      <ButtonImport
        variant="outlined"
        component="label"
        startIcon={<BsBoxArrowInUpRight />}
        disabled={loadingImport}
      >
        Nhập excel
        <input
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          type="file"
          hidden
          ref={inputRef}
          // onChange={onHandleImport}
        />
      </ButtonImport>
      <ButtonExport
        variant="outlined"
        startIcon={<BsArrowDownCircle />}
        // onClick={handleExportClient}
      >
        Tải excel
      </ButtonExport>
      <ButtonExport
        variant="outlined"
        startIcon={<BsFillStickiesFill />}
        // onClick={handleExportTemplate}
      >
        Biểu mẫu
      </ButtonExport>
      <ButtonAdd
        variant="contained"
        startIcon={<BsPlusCircle />}
        onClick={() => setSelected({ isNew: true })}
      >
        Thêm mới
      </ButtonAdd>
    </>
  );

  const validateValue = (row) => {
    const dataSelect = row;
    dataSelect.beginWork = Utils.checkIsDate(row.beginWork)
      ? Utils.convertToDateFormat(row.beginWork)
      : '';
    dataSelect.endWork = Utils.checkIsDate(row.endWork)
      ? Utils.convertToDateFormat(row.endWork)
      : '';
    return dataSelect;
  };

  const handleClickDetail = (row) => {
    const dataSelect = validateValue(row);
    setSelectedShowModal(dataSelect);
  };

  // const handleClickDetail = (row) => {
  //   const dataSelect = validateValue(row);
  //   setSelected(dataSelect);
  // };
  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Typography variant="h6" component="h6" sx={{ marginBottom: '10px' }}>
          DANH SÁCH CÔNG VIỆC
        </Typography>
        <Table
          filter={filter}
          rows={dataList}
          headCells={Columns(handleSearchColumn, deleteItem, usersList)}
          // totalRecords={totalRecords}
          onPageChange={(paging) => {
            setFilter((prev) => ({ ...prev, ...paging }));
          }}
          onClickDetail={handleClickDetail}
          loading={loading}
          renderHeader={renderHeader}
        />
        <FormDetail {...{ selected, setSelected, reloadData }} />
      </Box>
      <ModalFormDetail
        maxWidth="md"
        title={`Cập nhật: ${selectedShowModal?.name} `}
        open={selectedShowModal}
        onCloseModal={onCloseModal}
      >
        <UpdateJob
          selectedShowModal={selectedShowModal}
          setSelectedShowModal={setSelectedShowModal}
          onCloseModal={onCloseModal}
          reloadData={reloadData}
        />
      </ModalFormDetail>
    </>
  );
}
export { TaskJobPage };
