import React, { useState, useEffect, useCallback } from 'react';
import { Box, Grid, IconButton } from '@mui/material';
import {
  ModalFormDetail,
  TextInput,
  Selection,
  ComboBox,
  FileUploadScheduleUser,
} from 'components';
import EditIcon from '@mui/icons-material/Edit';
import { BtnCreateUpdate } from '../styles';
import ApiServices from 'services';
import { RESPONSE_STATUSES } from 'commons/constants';
import { CreateSubJob } from '../create-sub-job';
import { UpdateSubJob } from '../update-sub-job';
import { DisplaySubJob } from './display-sub-job';

const SEARCH_USER_OPTIONS = [
  { value: '0', content: 'Mới tạo' },
  { value: '1', content: 'Đánh giá' },
  { value: '2', content: 'Hoàn thành' },
];

const WORK_EVALUATION_OPTIONS = [
  { value: 'khong_danh_gia', content: 'Không đánh giá' },
  { value: 'dat', content: 'Đạt' },
  { value: 'khong_dat', content: 'Không đạt' },
];
const UpdateJob = ({ selectedShowModal, setSelectedShowModal, reloadData, onCloseModal }) => {
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const [userAssignerList, setUserAssignerList] = useState([]);
  const [userManagerList, setUserManagerList] = useState([]);
  const [clientCompanyList, setClientCompanyList] = useState([]);

  const [beginWork, setBeginWork] = useState(selectedShowModal?.beginWork);
  const [endWork, setEndWork] = useState(selectedShowModal?.endWork);
  const [status, setStatus] = useState(selectedShowModal?.status);
  const [note, setNote] = useState(selectedShowModal?.note);
  // kết quả công việc
  const [workUpdateResult, setWorkUpdateResult] = useState(selectedShowModal?.workUpdateResult);
  // Đánh giá công việc
  const [workEvaluation, setWorkEvaluation] = useState(selectedShowModal?.workEvaluation);

  // File đính kèm
  const [file, setFile] = useState([]);
  const [currentFile, setCurrentFile] = useState([]);

  const [arrAssigner, setArrAssigner] = useState([]);
  const [arrManager, setArrManager] = useState([]);
  const [arrParentClientCompanyId, setArrParentClientCompanyId] = useState([]);

  // Sub job
  const [subJobList, setSubJobList] = useState([]);
  const [selectedCreateSubJob, setSelectedCreateSubJob] = useState(null);
  const [selectedUpdateSubJob, setSelectedUpdateSubJob] = useState(null);

  const getClientCompanies = async (rowData) => {
    try {
      const res = await ApiServices.getClientCompanies();
      if (res?.data) {
        setClientCompanyList(res?.data || []);

        const arrParentClientCompanyId = res?.data?.filter((item) =>
          rowData?.arrParentClientCompanyId?.includes(item?.id),
        );

        setArrParentClientCompanyId(arrParentClientCompanyId);
      }
    } catch (error) {
      console.log('getClientCompanies Failed:::', error);
    }
  };

  const getUsers = async (rowData) => {
    try {
      const res = await ApiServices.getUsers();
      if (res?.data) {
        setUserManagerList(res?.data || []);
        setUserAssignerList(res?.data || []);

        const userManagerList = res?.data?.filter((item) =>
          rowData?.arrManager?.includes(item?.code),
        );
        setArrManager(userManagerList);

        const userAssignerList = res?.data?.filter((item) =>
          rowData?.arrAssigner?.includes(item?.code),
        );
        setArrAssigner(userAssignerList);
      }
    } catch (error) {
      console.log('getUsers Failed:::', error);
    }
  };

  const getSubJobByMainJob = async (rowData) => {
    if (!rowData?.code) return;

    try {
      const res = await ApiServices.getSubJobByMainJob(rowData?.code);
      if (res?.data) {
        setSubJobList(res?.data || []);
      }
    } catch (error) {
      console.log('getSubJobByMainJob Failed:::', error);
    }
  };

  useEffect(() => {
    const currentArrUrlfile = selectedShowModal?.arrUrlfile;

    if (currentArrUrlfile?.length > 0) {
      setCurrentFile(currentArrUrlfile || []);
    }

    getUsers(selectedShowModal);
    getClientCompanies(selectedShowModal);
    getSubJobByMainJob(selectedShowModal);
  }, [selectedShowModal]);

  const handleSubmit = async () => {
    if (loadingSubmit) return;

    const mainJobId = selectedShowModal?.id;
    const fileTmp = file?.map(([item]) => item);

    const dataSend = {
      ...selectedShowModal,
      beginWork,
      endWork,
      status,
      note,
      arrAssigner: arrAssigner.map((item) => item?.code),
      arrManager: arrManager.map((item) => item?.code),
      arrParentClientCompanyId: arrParentClientCompanyId.map((item) => item?.id),
      workUpdateResult,
      workEvaluation,
      file: fileTmp,
      arrUrlfile: currentFile || [],
    };

    setLoadingSubmit(true);
    return ApiServices.updateScheduleUserMainJob(mainJobId, dataSend)
      .then((res) => {
        if (res.status === RESPONSE_STATUSES.OK) {
          window.UIMessage?.success('Thêm mới thành công');
          setSelectedShowModal(null);
          reloadData();
        }
      })
      .catch((err) => {
        window.UIMessage?.error('Thêm mới thất bại !!!');
        console.log(err);
      })
      .finally(() => setLoadingSubmit(false));
  };

  const handleChangeArrAssigner = useCallback(
    (_, newValue) => {
      setArrAssigner(newValue || null);
    },
    [arrAssigner],
  );
  const handleChangeArrManager = useCallback(
    (_, newValue) => {
      setArrManager(newValue || null);
    },
    [arrManager],
  );

  const handleChangeArrParentClientCompanyId = useCallback(
    (_, newValue) => {
      setArrParentClientCompanyId(newValue || null);
    },
    [arrParentClientCompanyId],
  );

  const handleChangeBeginWork = useCallback(
    (e) => {
      setBeginWork(e.target.value);
    },
    [beginWork],
  );

  const handleChangeEndWork = useCallback(
    (e) => {
      setEndWork(e.target.value);
    },
    [endWork],
  );

  const handleChangeStatus = useCallback(
    (e) => {
      setStatus(e.target.value);
    },
    [status],
  );

  const handleChangeNote = useCallback(
    (e) => {
      setNote(e.target.value);
    },
    [note],
  );

  const handleChangeWorkUpdateResult = useCallback(
    (e) => {
      setWorkUpdateResult(e.target.value);
    },
    [workUpdateResult],
  );

  const handleChangeWorkEvaluate = useCallback(
    (e) => {
      setWorkEvaluation(e.target.value);
    },
    [workEvaluation],
  );

  const handleChangeFile = useCallback(
    (e) => {
      setFile(e.target.value);
    },
    [file],
  );
  const handleChangeCurrentFile = useCallback(
    (fileCurrent) => {
      setCurrentFile(fileCurrent || []);
    },
    [currentFile],
  );

  // Close modal
  const handleCloseModal = () => {
    onCloseModal();
  };

  // Close modal Create SubJob
  const handleCloseModalCreateSubJob = () => {
    setSelectedCreateSubJob(null);
  };

  const handleSelectedCreateSubJob = (data) => {
    setSelectedCreateSubJob(data);
  };

  const reloadDataAfterCreateSubJob = () => {
    getSubJobByMainJob(selectedShowModal);
    handleCloseModalCreateSubJob();
  };

  const reloadDataAfterDeleteSubJob = () => {
    getSubJobByMainJob(selectedShowModal);
  };

  const handleDeleteSubJob = async (row) => {
    if (!row?.id || loadingDelete) return;

    setLoadingDelete(true);
    return ApiServices.deleteScheduleUser(row?.id)
      .then((res) => {
        if (res.status === RESPONSE_STATUSES.OK) {
          window.UIMessage?.success('Xóa thành công');
          reloadDataAfterDeleteSubJob();
        }
      })
      .catch((err) => {
        window.UIMessage?.error('Xóa thất bại !!!');
        console.warn('deleteClientComplaint', err);
      })
      .finally(() => setLoadingDelete(false));
  };

  const handleCloseModalUpdateSubJob = () => {
    setSelectedUpdateSubJob(null);
  };

  const reloadDataAfterUpdateSubJob = () => {
    getSubJobByMainJob(selectedShowModal);
    handleCloseModalUpdateSubJob();
  };

  return (
    <div>
      <div>
        <span
          style={{
            color: 'blue',
          }}
        >
          Thiết lập công việc hiệu quả theo 5W(?){' '}
        </span>
      </div>

      <div style={{ fontSize: '20px', marginTop: '8px' }}>
        <b>Mục tiêu công việc:</b> <span>{selectedShowModal?.workObjective}</span>
      </div>

      <Grid container spacing={2} style={{ marginTop: '4px' }}>
        <Grid item xs={6} sm={6} md={6}>
          <TextInput
            required
            error={!beginWork}
            helperText={!beginWork ? 'Thời gian bắt đầu không được để trống' : ''}
            label="Thời gian bắt đầu"
            name="beginWork"
            onChange={handleChangeBeginWork}
            value={beginWork}
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          <TextInput
            required
            error={!endWork}
            helperText={!endWork ? 'Thời gian kết thúc không được để trống' : ''}
            label="Thời gian bắt đầu"
            name="endWork"
            onChange={handleChangeEndWork}
            value={endWork}
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          <Selection
            label="Trạng thái"
            size="small"
            displayNone={false}
            dataArrObject={SEARCH_USER_OPTIONS}
            onChange={handleChangeStatus}
            value={status}
          />
        </Grid>

        <Grid item xs={6} sm={6} md={6}>
          <ComboBox
            error={!arrAssigner?.length}
            helperText={!arrAssigner?.length && 'Vui lòng chọn người thực hiện'}
            disableCloseOnSelect
            options={userAssignerList}
            label="Người thực hiện"
            sx={{ width: '100%', marginTop: '8px' }}
            keyField="name"
            value={arrAssigner}
            onChange={handleChangeArrAssigner}
            multiple
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <ComboBox
            error={!arrParentClientCompanyId?.length}
            helperText={!arrParentClientCompanyId?.length && 'Vui lòng chọn Khách hàng'}
            disableCloseOnSelect
            options={clientCompanyList}
            label="Khách hàng"
            sx={{ width: '100%', marginTop: '8px' }}
            keyField="code"
            value={arrParentClientCompanyId}
            onChange={handleChangeArrParentClientCompanyId}
            multiple
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <TextInput
            required
            multiline={true}
            rows={4}
            label="Nội dung công việc"
            name="note"
            value={note}
            onChange={handleChangeNote}
          />
        </Grid>
      </Grid>

      <FileUploadScheduleUser
        label="File đính kèm"
        name="file"
        marinTop={1}
        // onBlur={handleBlur}
        onChange={handleChangeFile}
        value={file}
        multiple={true}
        isUploadOnlyContract={true}
        valueCurrent={currentFile}
        onChangeCurrent={handleChangeCurrentFile}
      />

      <Grid container spacing={1} style={{ marginTop: '8px' }}>
        <Grid item xs={12} sm={12} md={12}>
          <TextInput
            label="Kết quả công việc"
            name="name"
            value={workUpdateResult}
            onChange={handleChangeWorkUpdateResult}
          />
        </Grid>
      </Grid>

      <Box style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <b>Công việc con:</b>
        </div>
        <div
          style={{
            cursor: 'pointer',
          }}
          onClick={() => {
            if (selectedShowModal?.status !== 2) {
              return handleSelectedCreateSubJob(selectedShowModal);
            } else {
              return;
            }
          }}
        >
          <IconButton color="primary" aria-label="add to shopping cart">
            <EditIcon />
          </IconButton>
          <span
            style={{
              color: 'blue',
            }}
          >
            Thêm công việc con
          </span>
        </div>
      </Box>
      <Box style={{ marginTop: '8px' }}>
        <DisplaySubJob
          subJobList={subJobList}
          handleDeleteSubJob={handleDeleteSubJob}
          setSelectedUpdateSubJob={setSelectedUpdateSubJob}
          userAssignerList={userAssignerList}
        />
      </Box>

      <Grid container spacing={1} style={{ marginTop: '8px' }}>
        <Grid item xs={12} sm={12} md={12}>
          <ComboBox
            error={!arrManager?.length}
            helperText={!arrManager?.length && 'Vui lòng chọn Người theo dõi'}
            disableCloseOnSelect
            options={userManagerList}
            label="Người theo dõi"
            sx={{ width: '100%', marginTop: '8px' }}
            keyField="name"
            value={arrManager}
            onChange={handleChangeArrManager}
            multiple
          />
        </Grid>
      </Grid>

      <Grid container spacing={1} style={{ marginTop: '8px' }}>
        <Grid item xs={6} sm={6} md={6}>
          <Selection
            label="Đánh giá công việc"
            size="small"
            displayNone={false}
            dataArrObject={WORK_EVALUATION_OPTIONS}
            onChange={handleChangeWorkEvaluate}
            value={workEvaluation}
          />
        </Grid>
      </Grid>

      <Box sx={{ display: 'flex', py: 2 }}>
        <Box sx={{ marginLeft: 'auto' }}>
          <BtnCreateUpdate color="error" variant="contained" onClick={handleCloseModal}>
            Hủy
          </BtnCreateUpdate>
          {selectedShowModal?.status !== 2 && (
            <BtnCreateUpdate
              color="primary"
              loading={loadingSubmit}
              // disabled={isSubmitting}
              variant="contained"
              onClick={handleSubmit}
            >
              Lưu
            </BtnCreateUpdate>
          )}
        </Box>
      </Box>

      <ModalFormDetail
        maxWidth="sm"
        title="Thêm mới công việc con"
        open={selectedCreateSubJob}
        onCloseModal={handleCloseModalCreateSubJob}
      >
        <CreateSubJob
          dataMainJob={selectedCreateSubJob}
          onCloseCreateSubJob={handleCloseModalCreateSubJob}
          reloadData={reloadDataAfterCreateSubJob}
        />
      </ModalFormDetail>
      <ModalFormDetail
        maxWidth="sm"
        title={`Cập nhật: ${selectedUpdateSubJob?.name}`}
        open={selectedUpdateSubJob}
        onCloseModal={handleCloseModalUpdateSubJob}
      >
        <UpdateSubJob
          dataMainJob={selectedShowModal}
          dataSubJob={selectedUpdateSubJob}
          onCloseUpdateSubJob={handleCloseModalUpdateSubJob}
          reloadData={reloadDataAfterUpdateSubJob}
        />
      </ModalFormDetail>
    </div>
  );
};

export { UpdateJob };
