import React from 'react';
import Utils from 'commons/utils';
import { SearchInputColumn } from 'components';
import { Button, Avatar, AvatarGroup, Box, Tooltip } from '@mui/material';

const renderCreateBy = (row, usersList) => {
  const user = usersList.find((item) => item?.code === row?.createdBy);
  console.log({ user });
  if (user) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Avatar
          alt={user.code}
          src={`${process.env.REACT_APP_API_URL}/${user?.urlAvatar}`}
          // sx={{ width: 28, height: 28 }}
        />
        <Box sx={{ ml: 1 }}>
          <Box sx={{ fontWeight: 500 }}>{user?.name}</Box>
          {/* <Box sx={{ color: 'text.secondary', fontSize: '0.75rem' }}>({user?.code})</Box> */}
        </Box>
      </Box>
    );
  }

  return row?.createdBy;
};

const renderUserFollow = (arrManager, usersList) => {
  if (!arrManager?.length) return '';

  const userManager = usersList.filter((item) => arrManager?.includes(item?.code));
  console.log({ userManager });

  if (!userManager?.length) return '';

  return (
    <AvatarGroup max={5} sx={{ marginLeft: 'auto', marginRight: '15px', cursor: 'pointer' }}>
      {userManager.map((item) => (
        <Tooltip key={item?.code} title={item.name} placement="top-start" arrow>
          <Avatar
            alt={item.code}
            src={`${process.env.REACT_APP_API_URL}/${item?.urlAvatar}`}
            // sx={{ width: 28, height: 28 }}
          />
        </Tooltip>
      ))}
    </AvatarGroup>
  );
};

const renderStatus = (status) => {
  const jobStatus = {
    0: 'Mơi tạo',
    1: 'Đánh giá',
    2: 'Hoàn thành',
  };

  return jobStatus[status] || '';
};

export const Columns = (handleSearchColumn, deleteItem, usersList = []) => [
  {
    id: 'code',
    numeric: false,
    disablePadding: false,
    label: <SearchInputColumn title="Mã công việc" name="code" onChange={handleSearchColumn} />,
    width: '100px',
    align: 'center',
  },
  {
    id: 'name',
    numeric: true,
    align: 'center',
    disablePadding: false,
    label: <SearchInputColumn title="Tên công việc" name="name" onChange={handleSearchColumn} />,
    align: 'left',
  },
  {
    id: 'workObjective',
    numeric: true,
    align: 'center',
    disablePadding: false,
    label: (
      <SearchInputColumn
        title="Mục tiêu công việc"
        name="workObjective"
        onChange={handleSearchColumn}
      />
    ),
    align: 'left',
  },
  {
    id: 'beginWork',
    numeric: true,
    align: 'center',
    disablePadding: false,
    label: (
      <SearchInputColumn title="Thời gian bắt đầu" name="beginWork" onChange={handleSearchColumn} />
    ),
    align: 'left',
    render: (row) => Utils.formatDateFn(row.beginWork),
  },
  {
    id: 'endWork',
    numeric: true,
    align: 'center',
    disablePadding: false,
    label: (
      <SearchInputColumn title="Thời gian kết thúc" name="endWork" onChange={handleSearchColumn} />
    ),
    align: 'left',
    render: (row) => Utils.formatDateFn(row.endWork),
  },
  {
    id: 'workUpdateResult',
    numeric: true,
    align: 'center',
    disablePadding: false,
    label: (
      <SearchInputColumn
        title="Kết quả công việc"
        name="workUpdateResult"
        onChange={handleSearchColumn}
      />
    ),
    align: 'left',
  },
  {
    id: 'createdBy',
    numeric: true,
    align: 'center',
    disablePadding: false,
    label: <SearchInputColumn title="Người tạo" name="createdBy" onChange={handleSearchColumn} />,
    align: 'left',
    render: (row) => renderCreateBy(row, usersList),
  },
  {
    id: 'arrManager',
    numeric: true,
    align: 'center',
    disablePadding: false,
    label: (
      <SearchInputColumn title="Người theo dõi" name="arrManager" onChange={handleSearchColumn} />
    ),
    align: 'left',
    render: (row) => renderUserFollow(row?.arrManager || [], usersList),
  },
  {
    id: 'arrAssigner',
    numeric: true,
    align: 'center',
    disablePadding: false,
    label: (
      <SearchInputColumn title="Người thực hiện" name="arrAssigner" onChange={handleSearchColumn} />
    ),
    align: 'left',
    render: (row) => renderUserFollow(row?.arrAssigner || [], usersList),
  },
  {
    id: 'status',
    numeric: true,
    align: 'center',
    disablePadding: false,
    label: 'Trạng thái',
    align: 'center',
    render: (row) => renderStatus(row?.status),
  },
  {
    id: 'actions',
    numeric: false,
    disablePadding: false,
    width: '10px',
    fixed: 'right',
    label: 'Thao tác',
    render: (row) => (
      <Button
        variant="contained"
        size="small"
        color="error"
        // disabled={row?.status !== CLIENT_COMPLAIN_STATUS.ORIGIN}
        sx={{ borderRadius: '15px', textTransform: 'none' }}
        onClick={() => window.showConfirm(() => deleteItem(row))}
      >
        Xoá
      </Button>
    ),
  },
];
