import React from 'react';
import { Box, Grid, Button, Checkbox, IconButton } from '@mui/material';
import Utils from 'commons/utils';
import { Table } from 'components';
import DeleteIcon from '@mui/icons-material/Delete';

const renderStatus = (status) => {
  if (status === 2) {
    return <Checkbox checked color="primary" />;
  }

  return <Checkbox color="default" />;
};

const renderAssigner = (rowData, dataAssignerList) => {
  const findData = dataAssignerList?.find((item) => item?.code === rowData?.arrAssigner?.[0]);
  if (findData) {
    return findData?.name;
  }
  return '';
};

const DisplaySubJob = ({
  subJobList = [],
  handleDeleteSubJob,
  setSelectedUpdateSubJob,
  userAssignerList,
}) => {
  const validateValue = (row) => {
    const dataSelect = row;
    dataSelect.beginWork = Utils.checkIsDate(row.beginWork)
      ? Utils.convertToDateFormat(row.beginWork)
      : '';
    dataSelect.endWork = Utils.checkIsDate(row.endWork)
      ? Utils.convertToDateFormat(row.endWork)
      : '';
    return dataSelect;
  };

  const handleClickDetail = (row) => {
    const dataSelect = validateValue(row);
    setSelectedUpdateSubJob(dataSelect);
  };

  const columns = [
    {
      id: 'status',
      numeric: true,
      disablePadding: false,
      label: '#',
      align: 'center',
      width: '50px',
      render: (row) => renderStatus(row?.status),
    },
    {
      id: 'code',
      numeric: true,
      disablePadding: false,
      label: 'Mã CV con',
      align: 'center',
      width: '150px',
      render: (row) => {
        return (
          <Button
            sx={{
              textTransform: 'none',
            }}
            variant="text"
            onClick={() => handleClickDetail(row)}
          >
            {row?.code}
          </Button>
        );
      },
    },
    {
      id: 'name',
      numeric: true,
      disablePadding: false,
      label: 'Tên CV con',
      align: 'center',
      width: '150px',
    },
    {
      id: 'arrAssigner',
      numeric: true,
      disablePadding: false,
      label: 'Người thực hiện',
      align: 'center',
      width: '150px',
      render: (row) => renderAssigner(row, userAssignerList),
    },
    // {
    //   id: 'urlFile',
    //   numeric: true,
    //   disablePadding: false,
    //   label: 'File phụ lục',
    //   align: 'center',
    //   render: (row) => <ShowListFile maxWidth="200px" fileList={row?.urlFile} />,
    // },
    {
      id: 'createdOn',
      numeric: true,
      disablePadding: false,
      label: 'Ngày tạo',
      align: 'center',
      width: '130px',
      render: (row) => Utils.formatDateFn(row?.createdOn),
    },
    {
      id: 'note',
      numeric: true,
      disablePadding: false,
      label: '#',
      align: 'center',
      width: '60px',
      render: (row) => (
        <IconButton color="error" size="small" onClick={() => handleDeleteSubJob(row)}>
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  return (
    <Table
      rows={subJobList || []}
      headCells={columns}
      onPageChange={(paging) => {}}
      isHeader={false}
      // onClickDetail={handleClickDetail}
    />
  );
};

export { DisplaySubJob };
